/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import SEO from "../components/seo"
import Aside from "../components/aside"

const NotFoundPage = () => (
  <div
    sx={{
      // height: "100%",
      display: "flex",
      flexDirection: ["column", "row"],
    }}
  >
    <SEO title="Credits" />
    <article>
      <h1>Credits</h1>
      <h2>Canzoni e audio utilizate all'interno del podcast</h2>
      <ul>
        <li>
          <a href="https://www.youtube.com/watch?v=QYZ4_fD8u4A">
            The Basement Strut
          </a>{" "}
          by{" "}
          <a href="https://www.youtube.com/channel/UCxfzyJ-HvEZbGmubAmfcsfQ">
            The Whole Other
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=SjrObORuYw8">Swagger</a> by{" "}
          <a href="https://www.youtube.com/channel/UCL1zFMJb0sthwdAlGjGbdyg">
            Quincas Moreira
          </a>
        </li>
      </ul>
    </article>
    <Aside />
  </div>
)

export default NotFoundPage
